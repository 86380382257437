/**
 * Created by PanJiaChen on 16/11/18.
 */

export function notUndefined(obj, item) {
  try {
    return obj[item]
  } catch (error) {
    return ''
  }
}

export function base64ToFloat32(blob, type) {
  const fLen = blob.length / Float32Array.BYTES_PER_ELEMENT // How many floats can be made, but be even
  const dView = new DataView(
    new ArrayBuffer(Float32Array.BYTES_PER_ELEMENT)
  ) // ArrayBuffer/DataView to convert 4 bytes into 1 float.
  const fAry = new Float32Array(fLen) // Final Output at the correct size
  let p = 0 // Position
  for (let j = 0; j < fLen; j++) {
    p = j * 4
    dView.setUint8(0, blob.charCodeAt(p))
    dView.setUint8(1, blob.charCodeAt(p + 1))
    dView.setUint8(2, blob.charCodeAt(p + 2))
    dView.setUint8(3, blob.charCodeAt(p + 3))
    fAry[j] = dView.getFloat32(0, true)
  }
  return fAry
}

export function float32ToBase64(num, len) {
  const u8Arr = new Uint8Array(new Float32Array([num]).buffer).reverse()
  const buf = new ArrayBuffer(len)
  const u8ArrFull = new Uint8Array(buf)
  for (let k = 0; k < u8Arr.length; k++) {
    u8ArrFull[k] = u8Arr[k]
  }
  let binary = ''
  for (var i = 0; i < u8ArrFull.byteLength; i++) {
    binary += String.fromCharCode(u8ArrFull[i])
  }
  return window.btoa(binary)
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function enparseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][value ] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xDC00 && code <= 0xDFFF) i--
  }
  return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}'
  )
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

// base64转二进制数组
function base64to2(base64) {
  const map = { "0": 52, "1": 53, "2": 54, "3": 55, "4": 56, "5": 57, "6": 58, "7": 59, "8": 60, "9": 61, "A": 0, "B": 1, "C": 2, "D": 3, "E": 4, "F": 5, "G": 6, "H": 7, "I": 8, "J": 9, "K": 10, "L": 11, "M": 12, "N": 13, "O": 14, "P": 15, "Q": 16, "R": 17, "S": 18, "T": 19, "U": 20, "V": 21, "W": 22, "X": 23, "Y": 24, "Z": 25, "a": 26, "b": 27, "c": 28, "d": 29, "e": 30, "f": 31, "g": 32, "h": 33, "i": 34, "j": 35, "k": 36, "l": 37, "m": 38, "n": 39, "o": 40, "p": 41, "q": 42, "r": 43, "s": 44, "t": 45, "u": 46, "v": 47, "w": 48, "x": 49, "y": 50, "z": 51, "+": 62, "/": 63 };
  let len = base64.length * .75 // 转换为int8array所需长度
  base64 = base64.replace(/=*$/, '') // 去掉=号（占位的）
  const int8 = new Int8Array(len) //设置int8array视图
  let arr1, arr2, arr3, arr4, p = 0
  for (let i = 0; i < base64.length; i += 4) {
      arr1 = map[base64[i]] // 每次循环 都将base644个字节转换为3个int8array直接
      arr2 = map[base64[i + 1]]
      arr3 = map[base64[i + 2]]
      arr4 = map[base64[i + 3]]
      // 假设数据arr 数据 00101011 00101111 00110011 00110001
      int8[p++] = arr1 << 2 | arr2 >> 4
      // 上面的操作 arr1向左边移动2位 变为10101100
      // arr2 向右移动4位：00000010
      // | 为'与'操作: 10101110
      int8[p++] = arr2 << 4 | arr3 >> 2
      int8[p++] = arr3 << 6 | arr4
  }
  return int8
}

//二进制数组转字符串
function ab2str(buf) {
  return String.fromCharCode.apply(null, new Uint16Array(buf));
}

// 数据转换
function strValue(arr,type) {
  let buf = new ArrayBuffer(4);
  let view = new Uint8Array(buf);
  for(let i = 0; i < view.length; i++){
      view[i] = arr[i]
  }
  if(type) {
      return new Int32Array(buf)[0];
  }else {
      return new Float32Array(buf)[0];
  }
}

//解析数据
export function analyticalData(str,dec) {
   
  if(str) {
      let baser64Str = base64to2(str);
      return {
          SymbolName: ab2str(baser64Str.slice(0,12)),
          Bid: Number(dec ? strValue(baser64Str.slice(12,16)).toFixed(dec) : strValue(baser64Str.slice(12,16))),
          Ask: Number(dec ? strValue(baser64Str.slice(16,20)).toFixed(dec) : strValue(baser64Str.slice(16,20))),
          TimeStamp: Number(strValue(baser64Str.slice(20,24),1))
      };
  }else {
      return {
          SymbolName: '',
          Bid: '',
          Ask: '',
          TimeStamp: ''
      };
  }
}

export function getIMEI() {
  let clientid = localStorage.getItem('clientid')
  if(!clientid) {
    clientid =
      (Math.random() * 10000000).toString(16).substr(0, 4) +
      '_' +
      new Date().getTime() +
      '_' +
      Math.random().toString().substr(2, 5);
    localStorage.setItem('clientid', clientid)
  }
  return clientid
}

// 获取渠道
export function appCode() {
  const referrer = document.referrer.split('?')[1]
  const obj = {}
  if (referrer) {
    const referrArr = referrer.split('&');
    for (let i = 0; i < referrArr.length; i++) {
      obj[referrArr[i].split('=')[0]] = referrArr[i].split('=')[1]
    }
  }
  const nowParams = location.search.replace(/^\?/, '')
  const objParams = nowParams.split('&');
  const obj2 = {}
  for (let i = 0; i < objParams.length; i++) {
    obj2[objParams[i].split('=')[0]] = objParams[i].split('=')[1]
  }
  return Object.assign({}, obj, obj2)
}